<template>
    <div class="chatlist" @click="open_chat()">
        <div class="dp">
            <img :src="dp" alt="">
        </div>
        <div class="mid">
            <div class="name_flex">
                <div class="name">{{name}}</div>
                <verified :size="'18px'" :style="{'height': '18px'}" v-if="verified"/>
            </div>
            <div class="flex">
                <div v-if="me">
                    <seen :size="'15px'" :style="{'height': '15px'}" v-if="message.seen" class="seen"/>
                    <div v-else class="icon">
                        <seen :size="'15px'" v-if="message.delivered" :style="{'height': '15px'}"/>
                        <check :size="'15px'" v-else :style="{'height': '15px'}"/>
                    </div>
                </div>
                <div class="message">{{ message.text }}</div>
            </div>
        </div>
        <div class="right">
            <div class="no">
                <div v-if="no != 0" class="no_text">{{ no }}</div>
            </div>
            <div class="date">{{date}}</div>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

import moment from 'moment'

import verified from '../../icons/verified.vue'
import check from '../../icons/check.vue'
import seen from '../../icons/seen.vue'

export default {
    components: {
        check, verified, seen
    },
    props: ['chat'],
    data() {
        return {
            dp: '',
            name: '',
            verified: false,
            date: '',
            no: '',
            message: {
                'sender': '',
                'text': ''
            },

            checked: false
        }
    },
    computed: {
        me() {
            return this.message.sender == this.$root.$data.username
        },
        new_message_sent() {
            return this.$root.$data.new_message_sent
        },
        new_message_received() {
            return this.$root.$data.new_message_received
        },
        message_delivered_info() {
            return this.$root.$data.message_delivered_info
        },
        message_seen_info() {
            return this.$root.$data.message_seen_info
        }
    },
    watch: {
        new_message_sent(val) {
            if (val.chat_id == this.chat.chat_id) {
                this.message = val
            }
        },
        new_message_received(val) {
            if (val.chat_id == this.chat.chat_id) {
                this.message = val

                if (this.$route.name != 'chat') {
                    this.no = this.no > 9 ? '9+' : this.no + 1
                }
                
                let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                var m = moment.utc(val.date);
                this.date = m.clone().tz(timezone).calendar({
                    lastDay: 'ddd [at] HH:MM',
                    lastWeek: 'ddd [at] HH:MM'
                })
            }
        },
        message_delivered_info(val) {
            if (val.message_id == this.message.message_id) {
                this.message.delivered = true
            }
        },
        message_seen_info(val) {
            if (val.message_id == this.message.message_id) {
                this.message.seen = true
            }
        }
    },
    mounted() {
        db.collection(this.chat.chat_id).orderBy('time', 'desc').limit(10).get().then(messages => {
            if (messages.length != 0) {
                this.message = messages[0]

                let count = 0
                for (let x of messages) {
                    if (x.sender == this.$root.$data.username) {
                        continue
                    }
                    if (x.seen == false) {
                        count++
                    }
                }
                
                this.no = count > 9 ? '9+' : count


                let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                
                var m = moment.utc(this.message.date);
                this.date = m.clone().tz(timezone).calendar({
                    lastDay: 'ddd [at] HH:MM',
                    lastWeek: 'ddd [at] HH:MM'
                })
            }
        })

        db.collection('users').doc({'username': this.chat.user}).get()
        .then(user => {
            if (user == undefined) {
                
                let form = new FormData()
                form.append('username', this.chat.user)
                
                this.$http.post('/user/details', form)
                .then(data => {
                    let r = data.data
                    if (r.success) {
                        
                        this.dp = r.user.dp
                        this.name = r.user.firstname + ' ' + r.user.lastname
                        this.verified = r.user.verified
                        
                        db.collection('users').add(r.user)
                    }
                })
            } else {
                this.dp = user.dp
                this.name = user.firstname + ' ' + user.lastname
                this.verified = user.verified
            }
        })
    },
    methods: {
        open_chat() {
            this.no = 0
            localStorage.setItem('chat', JSON.stringify(this.chat))
            this.$router.push('/chat/' + this.chat.chat_id)
        }
    },
}
</script>

<style scoped>
    .chatlist {
        display: grid;
        grid-template-columns: max-content auto max-content;
        align-items: center;
        gap: 15px;
        padding: 10px 15px;
        /* border-bottom: 1px solid #ddd; */
        background-color: white;
        margin-top: 5px;
        /* border-radius: 5px; */
    }
    .dp {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dp img {
        width: 50px;
    }

    
    .mid {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .name_flex {
        display: flex;
        align-items: center;
        gap: 10px;
        fill: var(--main);
    }
    .name {
        font-weight: 600;
        color: #000;
        display: inline;
    }

    .flex {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 3px;
    }
    
    .icon {
        fill: gray;
    }
    .seen {
        fill: var(--main);
    }
    
    .message {
        font-size: 14px;
        display: inline;
    }

    .right {
        margin-left: auto;
        font-size: 14px;
    }
    .no {
        width: fit-content;
        height: 20px;
        margin-left: auto;
        border-radius: 50px;
        color: white;
        font-weight: 600;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .no_text {
        background-color: var(--main);
        padding: 0px 7px;
        height: 20px;
        border-radius: 50px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;

    }
    .date {
        font-size: 12px;
        margin-top: 3px;
    }
</style>